import React from "react";
import { MenuItemWithSlug } from "../type";

type MenuListWithImageProps = {
  title: string;
  items: MenuItemWithSlug[];
  handleClick: (slug: string) => void;
};

const MenuListWithImage: React.VFC<MenuListWithImageProps> = ({
  title,
  items,
  handleClick,
}: MenuListWithImageProps) => {
  return (
    <div>
      <p className="my-4 font-medium text-2xl">{title}</p>
      {items?.map((item) => (
        <a
          className="flex align-center cursor-pointer"
          key={item.id}
          onClick={() => handleClick(item.slug)}
          href="#blog"
        >
          {item.image && (
            <div
              className="w-16 h-16 bg-contain bg-no-repeat bg-center"
              style={{
                background: `url(${item.image})`,
              }}
            />
          )}
          <p className="ml-4 my-4 text-xs font-graphikthloop w-10/12">
            {item.label}
          </p>
        </a>
      ))}
    </div>
  );
};

export default MenuListWithImage;
