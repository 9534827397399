import { graphql, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import qs from "qs";
import parse from "html-react-parser";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

import TopNavWhite from "../components/TopNavWhite";
import Footer from "../components/Footer";
import MenuListWithImage from "../components/MenuList/MenuListWithImage";

import getStrapiContent from "../../functions/api/strapi";
import { Content, SocialType } from "../types/Blog";
import { MenuItemWithSlug } from "../components/MenuList/type";
import dayjs from "dayjs";
import SocialBar from "../components/SocialBar";
import BlogSearchInput from "../components/BlogSearchInput";
import "./index.css";
import withPages from "../hoc/withPages";

const transformContentToMenuItems = (
  contents: Content[]
): MenuItemWithSlug[] => {
  return contents.map((content) => ({
    id: content.id,
    value: content.attributes.slug,
    label: content.attributes.header,
    image: content.attributes.cover?.data?.attributes?.url,
    slug: content.attributes.slug,
  }));
};

const BlogContent = ({ data }) => {
  const [relatedBlog, setRelatedBlog] = useState<MenuItemWithSlug[]>([]);
  const categories = data.strapiContent.categories?.map(
    (category) => category.value
  );
  const blogItem = data.strapiContent;
  const details = data.strapiContent?.details;
  const author = {
    firstname: blogItem.author?.firstname || blogItem.createdBy?.firstname,
    lastname: blogItem.author?.lastname || blogItem.createdBy?.lastname,
    avatar: blogItem.author?.avatar?.file?.childImageSharp?.gatsbyImageData,
  };

  const query = qs.stringify(
    {
      filters: {
        categories: {
          value: {
            $in: categories || [],
          },
        },
      },
      sort: {
        updatedAt: "desc",
      },
      pagination: {
        pageSize: 5,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const handleClickRelatedBlog = (slug) => {
    navigate(`/blog/${slug}`);
  };
  useEffect(() => {
    const getRelatedBlog = async () => {
      const result = await getStrapiContent(query);
      if (result) {
        const relatedBlog = transformContentToMenuItems(result.data);
        const filteredRelatedBlog = relatedBlog.filter(
          (blog) => blog.slug !== blogItem.slug
        );
        setRelatedBlog(filteredRelatedBlog);
      }
    };
    getRelatedBlog();
  }, []);

  const getContent = async ({
    searchParams,
  }: {
    isOption: boolean;
    searchParams?: string;
  }) => {
    try {
      const query = qs.stringify(
        {
          filters: {
            header: {
              $containsi: searchParams,
            },
          },
          sort: {
            updatedAt: "desc",
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      const result = await getStrapiContent(query);
      if (result) {
        return result.data;
      }
    } catch (e) {
      console.log("e:", e);
    }
  };

  const handleShare = (type: SocialType) => {
    let url = "";
    switch (type) {
      case SocialType.Facebook:
        url =
          "https://www.facebook.com/sharer/sharer.php?u=" +
          `https://landing-page-development-web.web.app/blog/${blogItem.slug}`;
        break;
      case SocialType.Twitter:
        url =
          "https://twitter.com/intent/tweet?text=" +
          `https://landing-page-development-web.web.app/blog/${blogItem.slug}`;
        break;
      case SocialType.LinkedIn:
        url =
          "https://www.linkedin.com/sharing/share-offsite/?url=" +
          `https://landing-page-development-web.web.app/blog/${blogItem.slug}`;
        break;
      case SocialType.Email:
        url = `https://mail.google.com/mail/u/0/?ui=2&fs=1&tf=cm&su&body=https://landing-page-development-web.web.app/blog/${blogItem.slug}`;
        break;
      default:
    }

    window.open(url, "_blank");
  };
  return (
    <div style={{ height: "100vh" }}>
      <div className="bg-white">
        <div className="center-container">
          <TopNavWhite
            SearchComponent={() =>
              BlogSearchInput({ getContent, isBlogContent: true })
            }
          />
        </div>
      </div>
      <div className="relative flex justify-center align-center blog-content-layout">
        <div className=" mx-auto max-w-max-screen">
          <div className=" grid lg:grid-cols-2 align-center">
            <div className="mx-4 md:mx-12 lg:mx-8 py-8 md:py-10 lg:py-0">
              <div className="w-full flex">
                {blogItem.categories?.map((cat) => (
                  <div
                    key={cat.id}
                    className={`w-fit-content ml-3 first:ml-0 text-white bg-black px-3 py-1 rounded font-graphikthloop`}
                  >
                    {cat.label}
                  </div>
                ))}
              </div>
              <div className="header-title m-0 mt-2 md:mt-4 lg:mt-1.5">
                {blogItem.header}
              </div>
              <div className="text-base lg:text-lg font-graphikthloop mt-2 md:mt-2.5 lg:mt-3.5">
                {blogItem.description}
              </div>
              <div className="flex mt-4 md:mt-7 w-full">
                {author.avatar ? (
                  <GatsbyImage
                    imgClassName="w-10 h-10 rounded-full"
                    image={author.avatar}
                    alt="Avatar"
                  />
                ) : (
                  <StaticImage
                    src="../../static/image/COD.png"
                    width={40}
                    height={40}
                    className="rounded-full"
                    alt="Avatar"
                  />
                )}
                <div className="text-base ml-3 flex justify-center align-center">
                  <div>
                    {author.firstname}&nbsp;
                    {author.lastname}
                  </div>
                  <div>
                    &nbsp;•&nbsp;
                    {dayjs(blogItem.updatedAt).format("DD MMMM YYYY")}
                  </div>
                </div>
              </div>
            </div>
            <div className="blog-content-scope">
              <GatsbyImage
                image={blogItem.cover.file.childImageSharp.gatsbyImageData}
                alt={blogItem.header}
                imgClassName="blog-content-image"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="relative mx-auto max-w-max-screen">
          <div className="lg:flex pt-11 md:pt-12 lg:pb-28 lg:pt-20 mx-4 md:mx-12 lg:mx-8">
            <div className="absolute social-bar-left hidden lg:block">
              <SocialBar handleShare={handleShare} />
            </div>
            <div className="prose w-full prose-li:font-graphikthloop prose-p:font-graphikthloop prose-h1:leading-snug">
              {parse(details)}
            </div>
            {relatedBlog.length ? (
              <div className="pb-16 md:pb-24 lg:pb-0 lg:ml-14 ">
                <MenuListWithImage
                  title="บทความที่เกี่ยวข้อง"
                  items={relatedBlog}
                  handleClick={handleClickRelatedBlog}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default (props) => {
  const { data } = props;
  return withPages(BlogContent)({
    title: data.strapiContent.header,
    description: data.strapiContent.description,
    linkImage: data.strapiContent.cover.url,
    ...props,
  });
};
export const pageQuery = graphql`
  query BlogContentBySlug($slug: String!) {
    allStrapiCategory {
      nodes {
        id
        label
        value
      }
    }
    strapiContent(slug: { eq: $slug }) {
      id
      header
      description
      createdAt
      updatedAt
      categories {
        label
        value
        id
      }
      cover {
        url
        file {
          childImageSharp {
            gatsbyImageData(height: 480, placeholder: BLURRED)
          }
        }
      }
      details
      createdBy {
        id
        firstname
        lastname
      }
      author {
        firstname
        lastname
        avatar {
          file {
            childImageSharp {
              gatsbyImageData(width: 40, height: 40, placeholder: BLURRED)
            }
          }
          url
        }
      }
      slug
    }
  }
`;
