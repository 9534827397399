import React from "react";

import facebook from "../../../static/facebook.svg";
import mail from "../../../static/mail.svg";
import linkedIn from "../../../static/linkedin.svg";
import twitter from "../../../static/twitter.svg";
import { SocialType } from "../../types/Blog";

interface SocialBarProps {
  handleShare: (type: SocialType) => void;
}

const SocialBar = ({ handleShare }: SocialBarProps) => {
  return (
    <div className="flex flex-col align-center">
      <div>SHARES</div>
      <div>
        <img
          className="mt-6 mb-8 cursor-pointer"
          src={facebook}
          alt="facebook-logo"
          onClick={() => handleShare(SocialType.Facebook)}
        />
        <img
          className="my-8 cursor-pointer"
          src={twitter}
          alt="twitter-logo"
          onClick={() => handleShare(SocialType.Twitter)}
        />
        <img
          className="my-8 cursor-pointer"
          src={mail}
          alt="mail-logo"
          onClick={() => handleShare(SocialType.Email)}
        />
        <img
          className="my-8 cursor-pointer"
          src={linkedIn}
          alt="linkedIn-logo"
          onClick={() => handleShare(SocialType.LinkedIn)}
        />
      </div>
    </div>
  );
};

export default SocialBar;
