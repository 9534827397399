import { MenuItem } from "../components/MenuList/type";

export interface Categories {
  id: number;
  attributes: {
    label: string;
    value: string;
  };
}

export interface Content {
  id: number;
  attributes: {
    header: string;
    description: string;
    createdAt: Date;
    updatedAt: Date;
    createdBy: {
      id: number;
      firstname: string;
      lastname: string;
    };
    categories: {
      data: Categories[];
    };
    slug: string;
    cover: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
    author: {
      data: {
        id: number;
        attributes: {
          firstname: string;
          lastname: string;
          avatar: {
            data: {
              attributes: {
                url: string;
              };
            };
          };
        };
      };
    };
  };
}

type CategoryItem = MenuItem;

interface CreatedBy {
  id: number;
  firstname: string;
  lastname: string;
}

export interface BlogCardItem {
  id: number;
  header: string;
  description: string;
  coverURL: string;
  createdAt: Date;
  slug: string;
  updatedAt: Date;
  createdBy: CreatedBy;
  categories: CategoryItem[];
  author: Author;
}

export interface Author {
  firstname: string;
  lastname: string;
  avatar: string;
}

export enum SocialType {
  Facebook = "facebook",
  Twitter = "twitter",
  Email = "email",
  LinkedIn = "linkedIn",
}
